import type { TrackingData } from '@hubcms/domain-tracking';

import type { NonNullish } from '../domain/non-nullish';

export function isPersonalizationData(
  data?: TrackingData | null,
): data is TrackingData &
  NonNullish<
    Pick<
      TrackingData,
      | 'articleid'
      | 'itemdescription'
      | 'clickitemposition'
      | 'recommendationrequestid'
      | 'recommendationsource'
      | 'recommendationsegmentgroup'
    >
  > {
  return Boolean(
    data &&
      data.articleid &&
      data.itemdescription &&
      data.clickitemposition &&
      data.recommendationrequestid &&
      data.recommendationsource &&
      data.recommendationsegmentgroup,
  );
}
