import { type ReactElement, useMemo } from 'react';
import cx from 'classnames';
import { createTrackingClickHandler, useTrackingData } from '@hubcms/data-access-tracking';
import {
  createPersonalizationEvent,
  createGa4SelectContentEvent,
  createTeaserLinkUtmParams,
  mapPersonalizationData,
  mapGa4SelectContentData,
  mapTeaserLinkUtmParams,
} from '@hubcms/utils-tracking';
import type { TrackingData } from '@hubcms/domain-tracking';
import { isNonNull } from '@hubcms/utils-browser';
import { addSearchParams, createUrl } from '@hubcms/utils-url';

import styles from './teaser-link.module.scss';

type TeaserLinkProps = {
  isExternal?: boolean;
  url: string;
  className?: string;
  children?: ReactElement;
  trackingData?: TrackingData;
  [key: string]: unknown;
};

export const TeaserLink = (props: TeaserLinkProps) => {
  const { isExternal, url, trackingData: initialTrackingData, ...otherProps } = props;
  const trackingData = useTrackingData(initialTrackingData);
  const urlWithTracking = createUrl(url);
  const teaserLinkUtmData = mapTeaserLinkUtmParams(trackingData);
  const teaserLinkUtmParams = createTeaserLinkUtmParams(teaserLinkUtmData);
  urlWithTracking.search = addSearchParams(urlWithTracking.search, teaserLinkUtmParams);

  const handleOnClick = useMemo(() => {
    const personalizationData = mapPersonalizationData(trackingData);
    const selectGa4ContentData = mapGa4SelectContentData(trackingData);
    const personalizationEvent = personalizationData && createPersonalizationEvent(personalizationData);
    const selectContentEvent = selectGa4ContentData && createGa4SelectContentEvent(selectGa4ContentData);
    const trackingEvents = [personalizationEvent, selectContentEvent].filter(isNonNull);

    if (!trackingEvents.length) {
      return undefined;
    }
    return createTrackingClickHandler(trackingEvents);
  }, [trackingData]);

  const target = isExternal ? '_blank' : undefined;

  return (
    <a
      className={cx(props.className, styles.teaserLink)}
      href={urlWithTracking.toString()}
      onClick={handleOnClick}
      target={target}
      {...otherProps}
    >
      {props.children}
    </a>
  );
};
