import type { Ga4SelectContentEvent, GlitrSelectContentEvent } from '@hubcms/domain-tracking';

export type Ga4SelectContentData = Omit<Ga4SelectContentEvent, 'event' | 'articlearchive'>;

export function createGa4SelectContentEvent(data: Ga4SelectContentData): Ga4SelectContentEvent {
  return {
    event: 'select_content',
    articlearchive: false,
    articleauthor: data.articleauthor,
    articlebodycharactercount: data.articlebodycharactercount,
    articleid: data.articleid,
    articleimagecount: data.articleimagecount,
    articlelayout: data.articlelayout,
    articlepublicationdateinutc: data.articlepublicationdateinutc,
    articlesource: data.articlesource,
    articletaglist: data.articletaglist,
    articletype: data.articletype,
    articlewordcount: data.articlewordcount,
    clickitemblock: data.clickitemblock,
    clickitemname: data.clickitemname,
    clickitemposition: data.clickitemposition,
    clickitemurl: data.clickitemurl,
    pagesecuretype: data.pagesecuretype,
    recommendationsource: data.recommendationsource,
  };
}

export type GlitrSelectContentData = Omit<GlitrSelectContentEvent, 'event'>;

export function createGlitrSelectContentEvent(data: GlitrSelectContentData): GlitrSelectContentEvent {
  return {
    event: 'select_content',
    ...data,
  };
}
