import type { TrackingData } from '@hubcms/domain-tracking';

import type { NonNullish } from '../domain/non-nullish';

export function isGa4SelectContentData(
  data?: TrackingData | null,
): data is TrackingData &
  NonNullish<Pick<TrackingData, 'articleid' | 'clickitemname' | 'clickitemposition' | 'clickitemurl' | 'pagesecuretype'>> {
  return Boolean(
    data && data.articleid && data.clickitemname && data.clickitemposition && data.clickitemurl && data.pagesecuretype,
  );
}
