import type { PersonalizationEvent } from '@hubcms/domain-tracking';

export type PersonalizationData = Omit<PersonalizationEvent, 'event' | 'eventaction' | 'eventcategory'>;

export function createPersonalizationEvent(data: PersonalizationData): PersonalizationEvent {
  return {
    event: 'page_event',
    eventaction: 'click',
    eventcategory: 'personalisation',
    eventlabel: data.eventlabel,
    articleid: data.articleid,
    itemdescription: data.itemdescription,
    recommendationrank: data.recommendationrank,
    recommendationrequestid: data.recommendationrequestid,
    recommendationsource: data.recommendationsource,
    recommendationsegmentgroup: data.recommendationsegmentgroup,
  };
}
