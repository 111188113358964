import type { TrackingData } from '@hubcms/domain-tracking';

import type { PersonalizationData } from './create-personalization-event';
import { isPersonalizationData } from './is-personalization-data';

export function mapPersonalizationData(data?: TrackingData | null): PersonalizationData | null {
  if (!isPersonalizationData(data)) {
    return null;
  }

  return {
    eventlabel: data.articleid,
    articleid: data.pageid ?? null,
    itemdescription: data.itemdescription,
    recommendationrank: data.recommendationrank ?? data.clickitemposition,
    recommendationrequestid: data.recommendationrequestid,
    recommendationsource: data.recommendationsource,
    recommendationsegmentgroup: data.recommendationsegmentgroup,
  };
}
